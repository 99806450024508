import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6660315c = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _ce1f4054 = () => interopDefault(import('../pages/api-and-developers.vue' /* webpackChunkName: "pages/api-and-developers" */))
const _2a2009a4 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _08c1d3ac = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _110202c6 = () => interopDefault(import('../pages/contests/index.vue' /* webpackChunkName: "pages/contests/index" */))
const _5e0c05af = () => interopDefault(import('../pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _9795617c = () => interopDefault(import('../pages/glossary.vue' /* webpackChunkName: "pages/glossary" */))
const _ab5a1b34 = () => interopDefault(import('../pages/go-fly-a-kite.vue' /* webpackChunkName: "pages/go-fly-a-kite" */))
const _6c5954a0 = () => interopDefault(import('../pages/grades.vue' /* webpackChunkName: "pages/grades" */))
const _994461dc = () => interopDefault(import('../pages/gym-administrations/index.vue' /* webpackChunkName: "pages/gym-administrations/index" */))
const _1bb0efc6 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _3c09e272 = () => interopDefault(import('../pages/library.vue' /* webpackChunkName: "pages/library" */))
const _20cb571f = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _3438304c = () => interopDefault(import('../pages/merci.vue' /* webpackChunkName: "pages/merci" */))
const _5ecea8af = () => interopDefault(import('../pages/new/index.vue' /* webpackChunkName: "pages/new/index" */))
const _071db24c = () => interopDefault(import('../pages/new-password.vue' /* webpackChunkName: "pages/new-password" */))
const _26419d69 = () => interopDefault(import('../pages/newsletters/index.vue' /* webpackChunkName: "pages/newsletters/index" */))
const _69688e12 = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _0d5ef0d7 = () => interopDefault(import('../pages/oblyk.vue' /* webpackChunkName: "pages/oblyk" */))
const _5feddf14 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _27757f2a = () => interopDefault(import('../pages/report-problem.vue' /* webpackChunkName: "pages/report-problem" */))
const _9f678006 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _6b52c041 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _7fe916b7 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _1172def6 = () => interopDefault(import('../pages/success-account-deleting.vue' /* webpackChunkName: "pages/success-account-deleting" */))
const _59cef480 = () => interopDefault(import('../pages/support-us.vue' /* webpackChunkName: "pages/support-us" */))
const _6ae636b9 = () => interopDefault(import('../pages/about/indoor.vue' /* webpackChunkName: "pages/about/indoor" */))
const _60dde7b3 = () => interopDefault(import('../pages/about/partner-search.vue' /* webpackChunkName: "pages/about/partner-search" */))
const _2fa2c4a3 = () => interopDefault(import('../pages/areas/new.vue' /* webpackChunkName: "pages/areas/new" */))
const _ef3c0c1c = () => interopDefault(import('../pages/articles/new.vue' /* webpackChunkName: "pages/articles/new" */))
const _627dc1f6 = () => interopDefault(import('../pages/ascents/new.vue' /* webpackChunkName: "pages/ascents/new" */))
const _72cead5b = () => interopDefault(import('../pages/crags/new.vue' /* webpackChunkName: "pages/crags/new" */))
const _05422c3d = () => interopDefault(import('../pages/crags/search.vue' /* webpackChunkName: "pages/crags/search" */))
const _09d99cb0 = () => interopDefault(import('../pages/en/cookie-and-audience-measurement.vue' /* webpackChunkName: "pages/en/cookie-and-audience-measurement" */))
const _ec24d76e = () => interopDefault(import('../pages/en/terms-of-use.vue' /* webpackChunkName: "pages/en/terms-of-use" */))
const _7b02b5e0 = () => interopDefault(import('../pages/errors/right-required.vue' /* webpackChunkName: "pages/errors/right-required" */))
const _56d0499d = () => interopDefault(import('../pages/escalade-en/france/index.vue' /* webpackChunkName: "pages/escalade-en/france/index" */))
const _7942e333 = () => interopDefault(import('../pages/find/climbers.vue' /* webpackChunkName: "pages/find/climbers" */))
const _83ea68e8 = () => interopDefault(import('../pages/find/crags.vue' /* webpackChunkName: "pages/find/crags" */))
const _280e67e7 = () => interopDefault(import('../pages/find/guide-books.vue' /* webpackChunkName: "pages/find/guide-books" */))
const _5640f83a = () => interopDefault(import('../pages/find/gyms.vue' /* webpackChunkName: "pages/find/gyms" */))
const _05ef8dfa = () => interopDefault(import('../pages/fr/conditions-d-utilisation.vue' /* webpackChunkName: "pages/fr/conditions-d-utilisation" */))
const _9eeb450e = () => interopDefault(import('../pages/fr/cookie-et-mesure-d-audience.vue' /* webpackChunkName: "pages/fr/cookie-et-mesure-d-audience" */))
const _7adb0052 = () => interopDefault(import('../pages/guide-book-papers/find.vue' /* webpackChunkName: "pages/guide-book-papers/find" */))
const _7476c8d2 = () => interopDefault(import('../pages/guide-book-papers/new.vue' /* webpackChunkName: "pages/guide-book-papers/new" */))
const _18855641 = () => interopDefault(import('../pages/guide-book-papers/new-versions.vue' /* webpackChunkName: "pages/guide-book-papers/new-versions" */))
const _7eef2bd2 = () => interopDefault(import('../pages/guide-book-papers/recommended.vue' /* webpackChunkName: "pages/guide-book-papers/recommended" */))
const _6f37bd97 = () => interopDefault(import('../pages/guide-books/features.vue' /* webpackChunkName: "pages/guide-books/features" */))
const _5cd568cd = () => interopDefault(import('../pages/gyms/new.vue' /* webpackChunkName: "pages/gyms/new" */))
const _3d919dbc = () => interopDefault(import('../pages/gyms/starter.vue' /* webpackChunkName: "pages/gyms/starter" */))
const _1b8e1e94 = () => interopDefault(import('../pages/home/climbing-sessions/index.vue' /* webpackChunkName: "pages/home/climbing-sessions/index" */))
const _5e74f546 = () => interopDefault(import('../pages/home/community.vue' /* webpackChunkName: "pages/home/community" */))
const _6e5968e3 = () => interopDefault(import('../pages/home/community/followers.vue' /* webpackChunkName: "pages/home/community/followers" */))
const _159ad48b = () => interopDefault(import('../pages/home/community/subscribes.vue' /* webpackChunkName: "pages/home/community/subscribes" */))
const _860e4e6a = () => interopDefault(import('../pages/home/favorites.vue' /* webpackChunkName: "pages/home/favorites" */))
const _14a2030c = () => interopDefault(import('../pages/home/favorites/crags.vue' /* webpackChunkName: "pages/home/favorites/crags" */))
const _1618a0e8 = () => interopDefault(import('../pages/home/favorites/gyms.vue' /* webpackChunkName: "pages/home/favorites/gyms" */))
const _41f5a3d0 = () => interopDefault(import('../pages/home/guide-books/index.vue' /* webpackChunkName: "pages/home/guide-books/index" */))
const _69314450 = () => interopDefault(import('../pages/home/media.vue' /* webpackChunkName: "pages/home/media" */))
const _74aa10c8 = () => interopDefault(import('../pages/home/media/photos.vue' /* webpackChunkName: "pages/home/media/photos" */))
const _327cc01f = () => interopDefault(import('../pages/home/media/videos.vue' /* webpackChunkName: "pages/home/media/videos" */))
const _43f96b87 = () => interopDefault(import('../pages/home/messenger.vue' /* webpackChunkName: "pages/home/messenger" */))
const _31307298 = () => interopDefault(import('../pages/home/messenger/new.vue' /* webpackChunkName: "pages/home/messenger/new" */))
const _3130b458 = () => interopDefault(import('../pages/home/messenger/_conversationId/index.vue' /* webpackChunkName: "pages/home/messenger/_conversationId/index" */))
const _c36971ec = () => interopDefault(import('../pages/home/search-climbers.vue' /* webpackChunkName: "pages/home/search-climbers" */))
const _fb40eb42 = () => interopDefault(import('../pages/home/settings.vue' /* webpackChunkName: "pages/home/settings" */))
const _1155c98e = () => interopDefault(import('../pages/home/settings/avatar.vue' /* webpackChunkName: "pages/home/settings/avatar" */))
const _5c4290e8 = () => interopDefault(import('../pages/home/settings/banner.vue' /* webpackChunkName: "pages/home/settings/banner" */))
const _8ec12b84 = () => interopDefault(import('../pages/home/settings/connection.vue' /* webpackChunkName: "pages/home/settings/connection" */))
const _12a284b8 = () => interopDefault(import('../pages/home/settings/general.vue' /* webpackChunkName: "pages/home/settings/general" */))
const _83b57290 = () => interopDefault(import('../pages/home/settings/notifications.vue' /* webpackChunkName: "pages/home/settings/notifications" */))
const _058ba843 = () => interopDefault(import('../pages/home/settings/others.vue' /* webpackChunkName: "pages/home/settings/others" */))
const _18596898 = () => interopDefault(import('../pages/home/settings/partner.vue' /* webpackChunkName: "pages/home/settings/partner" */))
const _37dc4f58 = () => interopDefault(import('../pages/home/settings/privacy.vue' /* webpackChunkName: "pages/home/settings/privacy" */))
const _dde2a9d6 = () => interopDefault(import('../pages/maps/climbers.vue' /* webpackChunkName: "pages/maps/climbers" */))
const _3fccdeea = () => interopDefault(import('../pages/maps/crags.vue' /* webpackChunkName: "pages/maps/crags" */))
const _4854c9b4 = () => interopDefault(import('../pages/maps/guide-book-papers.vue' /* webpackChunkName: "pages/maps/guide-book-papers" */))
const _1860011c = () => interopDefault(import('../pages/maps/gyms.vue' /* webpackChunkName: "pages/maps/gyms" */))
const _5afed83f = () => interopDefault(import('../pages/maps/my-map.vue' /* webpackChunkName: "pages/maps/my-map" */))
const _70efbaf7 = () => interopDefault(import('../pages/newsletters/new.vue' /* webpackChunkName: "pages/newsletters/new" */))
const _1f53be61 = () => interopDefault(import('../pages/newsletters/subscribe.vue' /* webpackChunkName: "pages/newsletters/subscribe" */))
const _1a74afe0 = () => interopDefault(import('../pages/newsletters/successful-subscribe.vue' /* webpackChunkName: "pages/newsletters/successful-subscribe" */))
const _53db4697 = () => interopDefault(import('../pages/newsletters/successful-unsubscribe.vue' /* webpackChunkName: "pages/newsletters/successful-unsubscribe" */))
const _d585e6b0 = () => interopDefault(import('../pages/newsletters/unsubscribe.vue' /* webpackChunkName: "pages/newsletters/unsubscribe" */))
const _dad56286 = () => interopDefault(import('../pages/organizations/new.vue' /* webpackChunkName: "pages/organizations/new" */))
const _0449cac6 = () => interopDefault(import('../pages/words/new.vue' /* webpackChunkName: "pages/words/new" */))
const _2adc54fe = () => interopDefault(import('../pages/ascents/indoor/new.vue' /* webpackChunkName: "pages/ascents/indoor/new" */))
const _2e5920e3 = () => interopDefault(import('../pages/ascents/outdoor/new.vue' /* webpackChunkName: "pages/ascents/outdoor/new" */))
const _ae20fd70 = () => interopDefault(import('../pages/home/ascents/indoor/index.vue' /* webpackChunkName: "pages/home/ascents/indoor/index" */))
const _4b59e8ba = () => interopDefault(import('../pages/home/ascents/outdoor.vue' /* webpackChunkName: "pages/home/ascents/outdoor" */))
const _65410886 = () => interopDefault(import('../pages/home/ascents/outdoor/index.vue' /* webpackChunkName: "pages/home/ascents/outdoor/index" */))
const _e683502e = () => interopDefault(import('../pages/home/ascents/outdoor/analytiks.vue' /* webpackChunkName: "pages/home/ascents/outdoor/analytiks" */))
const _ba788042 = () => interopDefault(import('../pages/home/ascents/outdoor/projects.vue' /* webpackChunkName: "pages/home/ascents/outdoor/projects" */))
const _19da0e39 = () => interopDefault(import('../pages/home/ascents/outdoor/tick-list.vue' /* webpackChunkName: "pages/home/ascents/outdoor/tick-list" */))
const _587e959f = () => interopDefault(import('../pages/home/guide-books/add.vue' /* webpackChunkName: "pages/home/guide-books/add" */))
const _ac924744 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cfbb02d4 = () => interopDefault(import('../pages/home/climbing-sessions/_sessionDate/index.vue' /* webpackChunkName: "pages/home/climbing-sessions/_sessionDate/index" */))
const _57fbd998 = () => interopDefault(import('../pages/escalade-en/france/_departmentNumber/_departementName/index.vue' /* webpackChunkName: "pages/escalade-en/france/_departmentNumber/_departementName/index" */))
const _44da840a = () => interopDefault(import('../pages/climbers/_userName.vue' /* webpackChunkName: "pages/climbers/_userName" */))
const _594cc784 = () => interopDefault(import('../pages/climbers/_userName/index.vue' /* webpackChunkName: "pages/climbers/_userName/index" */))
const _539361c5 = () => interopDefault(import('../pages/climbers/_userName/ascents.vue' /* webpackChunkName: "pages/climbers/_userName/ascents" */))
const _834ce5e6 = () => interopDefault(import('../pages/climbers/_userName/ascents/indoor.vue' /* webpackChunkName: "pages/climbers/_userName/ascents/indoor" */))
const _044f5f32 = () => interopDefault(import('../pages/climbers/_userName/ascents/outdoor.vue' /* webpackChunkName: "pages/climbers/_userName/ascents/outdoor" */))
const _e9b217fe = () => interopDefault(import('../pages/climbers/_userName/followers.vue' /* webpackChunkName: "pages/climbers/_userName/followers" */))
const _f9dfeb60 = () => interopDefault(import('../pages/climbers/_userName/media.vue' /* webpackChunkName: "pages/climbers/_userName/media" */))
const _4bb4ed50 = () => interopDefault(import('../pages/climbers/_userName/media/photos.vue' /* webpackChunkName: "pages/climbers/_userName/media/photos" */))
const _09879ca7 = () => interopDefault(import('../pages/climbers/_userName/media/videos.vue' /* webpackChunkName: "pages/climbers/_userName/media/videos" */))
const _db02a5a6 = () => interopDefault(import('../pages/climbers/_userName/subscribes.vue' /* webpackChunkName: "pages/climbers/_userName/subscribes" */))
const _6db234e5 = () => interopDefault(import('../pages/comments/_commentId/index.vue' /* webpackChunkName: "pages/comments/_commentId/index" */))
const _fde4606a = () => interopDefault(import('../pages/escalade-autour-de/_townName/index.vue' /* webpackChunkName: "pages/escalade-autour-de/_townName/index" */))
const _558566fc = () => interopDefault(import('../pages/gr/_gymAndRouteId/index.vue' /* webpackChunkName: "pages/gr/_gymAndRouteId/index" */))
const _55652536 = () => interopDefault(import('../pages/grs/_gymId/index.vue' /* webpackChunkName: "pages/grs/_gymId/index" */))
const _4471bc59 = () => interopDefault(import('../pages/gym-chains/_gymChainId.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId" */))
const _11e847c8 = () => interopDefault(import('../pages/gym-chains/_gymChainId/index.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/index" */))
const _764bdcea = () => interopDefault(import('../pages/photos/_photoId/index.vue' /* webpackChunkName: "pages/photos/_photoId/index" */))
const _75269a1d = () => interopDefault(import('../pages/videos/_videoId/index.vue' /* webpackChunkName: "pages/videos/_videoId/index" */))
const _3f09a8e6 = () => interopDefault(import('../pages/alerts/_alertId/edit.vue' /* webpackChunkName: "pages/alerts/_alertId/edit" */))
const _04089f52 = () => interopDefault(import('../pages/authors/_authorId/cover.vue' /* webpackChunkName: "pages/authors/_authorId/cover" */))
const _7485a2bf = () => interopDefault(import('../pages/authors/_authorId/edit.vue' /* webpackChunkName: "pages/authors/_authorId/edit" */))
const _09ad3dc7 = () => interopDefault(import('../pages/comments/_commentId/edit.vue' /* webpackChunkName: "pages/comments/_commentId/edit" */))
const _2ff51291 = () => interopDefault(import('../pages/guide-book-pdfs/_guideBookPdfId/edit.vue' /* webpackChunkName: "pages/guide-book-pdfs/_guideBookPdfId/edit" */))
const _597e0ad1 = () => interopDefault(import('../pages/guide-book-webs/_guideBookWebId/edit.vue' /* webpackChunkName: "pages/guide-book-webs/_guideBookWebId/edit" */))
const _68ebacc2 = () => interopDefault(import('../pages/links/_linkId/edit.vue' /* webpackChunkName: "pages/links/_linkId/edit" */))
const _9c83641c = () => interopDefault(import('../pages/areas/_areaId/_areaName.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName" */))
const _408116f5 = () => interopDefault(import('../pages/areas/_areaId/_areaName/index.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/index" */))
const _6a488b3e = () => interopDefault(import('../pages/areas/_areaId/_areaName/crags.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/crags" */))
const _eef8a124 = () => interopDefault(import('../pages/areas/_areaId/_areaName/photos.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/photos" */))
const _e83d0798 = () => interopDefault(import('../pages/articles/_articleId/_articleName/index.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/index" */))
const _0d1b1d3e = () => interopDefault(import('../pages/crag-routes/_cragRouteId/_cragRouteName/index.vue' /* webpackChunkName: "pages/crag-routes/_cragRouteId/_cragRouteName/index" */))
const _432ca096 = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName" */))
const _ee824b10 = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName/index.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName/index" */))
const _74b6a3e1 = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName/maps.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName/maps" */))
const _47fe5c4b = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName/photos.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName/photos" */))
const _54780d3a = () => interopDefault(import('../pages/crags/_cragId/_cragName.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName" */))
const _491f7f86 = () => interopDefault(import('../pages/crags/_cragId/_cragName/index.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/index" */))
const _d91d9378 = () => interopDefault(import('../pages/crags/_cragId/_cragName/guide-books.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/guide-books" */))
const _a866c8b8 = () => interopDefault(import('../pages/crags/_cragId/_cragName/links.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/links" */))
const _1ba76aff = () => interopDefault(import('../pages/crags/_cragId/_cragName/maps/index.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/maps/index" */))
const _4b79af26 = () => interopDefault(import('../pages/crags/_cragId/_cragName/photos.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/photos" */))
const _d8ccae9c = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/index.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/index" */))
const _5b3fa25a = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName" */))
const _0e3b7d5d = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/index.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/index" */))
const _905a48aa = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/alternatives.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/alternatives" */))
const _ced3be6e = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/crags.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/crags" */))
const _42d04e78 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/links.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/links" */))
const _042674b2 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/map.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/map" */))
const _72161606 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/photos.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/photos" */))
const _2ac18718 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/points-of-sale.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/points-of-sale" */))
const _509fe5ac = () => interopDefault(import('../pages/gyms/_gymId/_gymName.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName" */))
const _a24709a2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/index" */))
const _1b4ef272 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/followers.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/followers" */))
const _d2e544be = () => interopDefault(import('../pages/newsletters/_newsletterId/_newsletterName/index.vue' /* webpackChunkName: "pages/newsletters/_newsletterId/_newsletterName/index" */))
const _74cb3850 = () => interopDefault(import('../pages/organizations/_organizationId/_organizationName.vue' /* webpackChunkName: "pages/organizations/_organizationId/_organizationName" */))
const _6912a85b = () => interopDefault(import('../pages/organizations/_organizationId/_organizationName/index.vue' /* webpackChunkName: "pages/organizations/_organizationId/_organizationName/index" */))
const _18bdf41a = () => interopDefault(import('../pages/words/_wordId/_slugName/index.vue' /* webpackChunkName: "pages/words/_wordId/_slugName/index" */))
const _3f1a31a6 = () => interopDefault(import('../pages/alerts/_alertableType/_alertableId/new.vue' /* webpackChunkName: "pages/alerts/_alertableType/_alertableId/new" */))
const _58cef074 = () => interopDefault(import('../pages/articles/_articleId/_articleName/add-crags.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/add-crags" */))
const _1e658262 = () => interopDefault(import('../pages/articles/_articleId/_articleName/add-guide-books.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/add-guide-books" */))
const _3203ac99 = () => interopDefault(import('../pages/articles/_articleId/_articleName/cover.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/cover" */))
const _1c3f5ed0 = () => interopDefault(import('../pages/articles/_articleId/_articleName/edit.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/edit" */))
const _ada01be2 = () => interopDefault(import('../pages/articles/_articleId/_articleName/photos.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/photos" */))
const _0e05b92c = () => interopDefault(import('../pages/comments/_commentableType/_commentableId/new.vue' /* webpackChunkName: "pages/comments/_commentableType/_commentableId/new" */))
const _2365bd23 = () => interopDefault(import('../pages/contests/_gymId/_contestId/print-results.vue' /* webpackChunkName: "pages/contests/_gymId/_contestId/print-results" */))
const _1040141a = () => interopDefault(import('../pages/links/_linkableType/_linkableId/new.vue' /* webpackChunkName: "pages/links/_linkableType/_linkableId/new" */))
const _7ea7e4ea = () => interopDefault(import('../pages/newsletters/_newsletterId/_newsletterName/edit.vue' /* webpackChunkName: "pages/newsletters/_newsletterId/_newsletterName/edit" */))
const _17ff837c = () => interopDefault(import('../pages/newsletters/_newsletterId/_newsletterName/photos.vue' /* webpackChunkName: "pages/newsletters/_newsletterId/_newsletterName/photos" */))
const _7f45f8b7 = () => interopDefault(import('../pages/photos/_illustrableType/_illustrableId/new.vue' /* webpackChunkName: "pages/photos/_illustrableType/_illustrableId/new" */))
const _19c053ed = () => interopDefault(import('../pages/reports/_reportableType/_reportableId/new.vue' /* webpackChunkName: "pages/reports/_reportableType/_reportableId/new" */))
const _51256c14 = () => interopDefault(import('../pages/versions/_versionType/_versionId/changes.vue' /* webpackChunkName: "pages/versions/_versionType/_versionId/changes" */))
const _fc2a4c00 = () => interopDefault(import('../pages/videos/_viewableType/_viewableId/new.vue' /* webpackChunkName: "pages/videos/_viewableType/_viewableId/new" */))
const _158dda0e = () => interopDefault(import('../pages/words/_wordId/_slugName/edit.vue' /* webpackChunkName: "pages/words/_wordId/_slugName/edit" */))
const _b22b3ebc = () => interopDefault(import('../pages/photos/_illustrableType/_illustrableId/_photoId/edit.vue' /* webpackChunkName: "pages/photos/_illustrableType/_illustrableId/_photoId/edit" */))
const _17f28cc2 = () => interopDefault(import('../pages/videos/_viewableType/_viewableId/_videoId/edit.vue' /* webpackChunkName: "pages/videos/_viewableType/_viewableId/_videoId/edit" */))
const _f9ed8f5c = () => interopDefault(import('../pages/gym-chains/_gymChainId/banner.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/banner" */))
const _797653b0 = () => interopDefault(import('../pages/gym-chains/_gymChainId/edit.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/edit" */))
const _da5a1a5e = () => interopDefault(import('../pages/gym-chains/_gymChainId/logo.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/logo" */))
const _53d6e42e = () => interopDefault(import('../pages/areas/_areaId/_areaName/add-crag.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/add-crag" */))
const _211c4c92 = () => interopDefault(import('../pages/areas/_areaId/_areaName/edit.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/edit" */))
const _3369e93a = () => interopDefault(import('../pages/crags/_cragId/_cragName/add-on-area.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/add-on-area" */))
const _0fe12587 = () => interopDefault(import('../pages/crags/_cragId/_cragName/add-on-guide-book.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/add-on-guide-book" */))
const _fe573122 = () => interopDefault(import('../pages/crags/_cragId/_cragName/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/edit" */))
const _61790147 = () => interopDefault(import('../pages/crags/_cragId/_cragName/new-pdf-guide-book.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/new-pdf-guide-book" */))
const _e84ed2b6 = () => interopDefault(import('../pages/crags/_cragId/_cragName/new-web-guide-book.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/new-web-guide-book" */))
const _7ecea5e8 = () => interopDefault(import('../pages/crags/_cragId/_cragName/approaches/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/approaches/new" */))
const _178bdc6d = () => interopDefault(import('../pages/crags/_cragId/_cragName/maps/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/maps/edit" */))
const _24d3f376 = () => interopDefault(import('../pages/crags/_cragId/_cragName/parks/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/parks/new" */))
const _1e340480 = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/new" */))
const _4c1514ee = () => interopDefault(import('../pages/crags/_cragId/_cragName/sectors/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/sectors/new" */))
const _82e3eb8a = () => interopDefault(import('../pages/crags/_cragId/_cragName/approaches/_approachId/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/approaches/_approachId/edit" */))
const _4c0353c0 = () => interopDefault(import('../pages/crags/_cragId/_cragName/parks/_parkId/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/parks/_parkId/edit" */))
const _f74ac76c = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/edit" */))
const _41027af5 = () => interopDefault(import('../pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/edit" */))
const _7767315a = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/new" */))
const _4acadb86 = () => interopDefault(import('../pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/routes/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/routes/new" */))
const _7985e6bd = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/_ascentCragRouteId/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/_ascentCragRouteId/edit" */))
const _226a04c6 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/add-crag.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/add-crag" */))
const _9744a47c = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/cover.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/cover" */))
const _171d064f = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/edit.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/edit" */))
const _61ac04b2 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/new.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/new" */))
const _e21c246c = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/_placeOfSaleId/edit.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/_placeOfSaleId/edit" */))
const _27b96493 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/administration-request.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/administration-request" */))
const _5957b792 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/administrator-required.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/administrator-required" */))
const _b572ee8c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/index" */))
const _756708c2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/banner.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/banner" */))
const _5e5c063d = () => interopDefault(import('../pages/gyms/_gymId/_gymName/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/edit" */))
const _77b8a55e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/logo.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/logo" */))
const _6a9aa15a = () => interopDefault(import('../pages/gyms/_gymId/_gymName/ranking.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/ranking" */))
const _e32026b0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/select-space.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/select-space" */))
const _451ab2a3 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/index" */))
const _310662de = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/administrators/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/administrators/index" */))
const _2c4b1a4e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/index" */))
const _457d5c8d = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/climbing-styles/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/climbing-styles/index" */))
const _6cd0d082 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/comments/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/comments/index" */))
const _6e8f042c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/index" */))
const _743374e8 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/first-difficulty-system.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/first-difficulty-system" */))
const _b32197de = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/first-space.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/first-space" */))
const _095ed747 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/index" */))
const _385e6672 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/indoor-subscriptions/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/indoor-subscriptions/index" */))
const _56afa90b = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/index" */))
const _e6b8450c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/levels/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/levels/index" */))
const _233e6147 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/openers/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/openers/index" */))
const _14775ed8 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/index" */))
const _cf166290 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/ranking-systems/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/ranking-systems/index" */))
const _76771057 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/tree-structures.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/tree-structures" */))
const _0d7e17ba = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/videos/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/videos/index" */))
const _77a0d6b1 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/new" */))
const _33d5d3c2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/administrators/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/administrators/new" */))
const _3ac1a732 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/new" */))
const _0e127090 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/new" */))
const _29a45756 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/new" */))
const _915f71ce = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/new" */))
const _2b67de55 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/openers/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/openers/new" */))
const _2d69863c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/new" */))
const _1538b5f0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/routes/statistics.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/routes/statistics" */))
const _d4ea169c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/routes/tables.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/routes/tables" */))
const _57e4e654 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/space-groups/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/space-groups/new" */))
const _aa92ce88 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/spaces/edit-three-d.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/spaces/edit-three-d" */))
const _44fc4e34 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/three-d-assets/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/three-d-assets/new" */))
const _89735df4 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/_championshipId.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/_championshipId" */))
const _4e9109ee = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/_championshipId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/_championshipId/index" */))
const _58adb06d = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/_championshipId/results.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/_championshipId/results" */))
const _8edd3a30 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId" */))
const _6ef21b6b = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/index" */))
const _07eae2e3 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/banner.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/banner" */))
const _0d9922fe = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/edit" */))
const _50f3ee17 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/participants.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/participants" */))
const _4ac1c04f = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/results.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/results" */))
const _7a73e00d = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/time-line.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/time-line" */))
const _6d15ffae = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/index" */))
const _a96c50ae = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/index" */))
const _499944a2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/index" */))
const _0241a2c4 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/administrators/_gymAdministratorId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/administrators/_gymAdministratorId/edit" */))
const _2ab075de = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/edit" */))
const _704ffc04 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/print.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/print" */))
const _30f19ee0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/openers/_gymOpenerId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/openers/_gymOpenerId/edit" */))
const _bf8cfbec = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/print.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/print" */))
const _46c1ba86 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/space-groups/_gymSpaceGroupId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/space-groups/_gymSpaceGroupId/edit" */))
const _6ee55a66 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/edit-three-d.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/edit-three-d" */))
const _0fa1dbd8 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/take-three-d-picture.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/take-three-d-picture" */))
const _171164a7 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/three-d-assets/_threeDAssetId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/three-d-assets/_threeDAssetId/edit" */))
const _0dba2414 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/new" */))
const _3028746e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/_gymGradeLineId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/_gymGradeLineId/edit" */))
const _0bce15d0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/rankers/_contestId/_contestName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/rankers/_contestId/_contestName/index" */))
const _d0929744 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/championships/_championshipId/_championshipName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/championships/_championshipId/_championshipName/index" */))
const _01506a3c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName" */))
const _53e51fe5 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/index" */))
const _311e7270 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/my-contest.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/my-contest" */))
const _81f4e36e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/results.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/results" */))
const _76a61a02 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/index" */))
const _18955726 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/edit" */))
const _554a57f8 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/select-sector.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/select-sector" */))
const _6f94500a = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-3d.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-3d" */))
const _cbdf173c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-plan.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-plan" */))
const _11dd8ecb = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/new" */))
const _2e01dc23 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/edit" */))
const _40273475 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/picture.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/picture" */))
const _3f6805a3 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/thumbnail.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/thumbnail" */))
const _365942f2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/edit" */))
const _24734998 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/routes/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/routes/new" */))
const _43558391 = () => interopDefault(import('../pages/organizations/_organizationId/_organizationName/edit.vue' /* webpackChunkName: "pages/organizations/_organizationId/_organizationName/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6660315c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"À propos d'Oblyk","metaDescription":"En savoir plus sur Oblyk, pourquoi et qui est derrière le projet"},"en":{"metaTitle":"About Oblyk","metaDescription":"Learn more about Oblyk, why and who is behind the project"}}}},
    name: "about"
  }, {
    path: "/api-and-developers",
    component: _ce1f4054,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"API des falaises et voies d'escalades","metaDescription":"Les API d'Oblyk vous permettes de consulter une grande base de donnée des falaises et voies d'escalade en France et dans le monde"},"en":{"metaTitle":"Climbing crags and routes API","metaDescription":"Oblyk's APIs allow you to consult a large database of crags and climbing routes in France and in the world"}}}},
    name: "api-and-developers"
  }, {
    path: "/articles",
    component: _2a2009a4,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Les actualités d'Oblyk","metaDescription":"Voir toutes les actualités d'Oblyk, la communauté de grimpeur"},"en":{"metaTitle":"Oblyk news","metaDescription":"View all Oblyk news, the climbing community"}}}},
    name: "articles"
  }, {
    path: "/contact",
    component: _08c1d3ac,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Contacter l'équipe d'Oblyk","metaDescription":"Pour toute question, toute proposition, ou tout simplement pour nous aider, n'hésitez pas à nous contacter !"},"en":{"metaTitle":"Contact the Oblyk team","metaDescription":"For any question, any proposal, or simply to help us, do not hesitate to contact us!"}}},"test":true},
    name: "contact"
  }, {
    path: "/contests",
    component: _110202c6,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Les contests","metaDescription":"Trouver et participer à un contest organiser par une salles d'escalade"},"en":{"metaTitle":"Opens","metaDescription":"Find and take part in a competition organised by a climbing gym"}}}},
    name: "contests"
  }, {
    path: "/delete-account",
    component: _5e0c05af,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Supprimer mon compte","metaDescription":"Supprimez votre compte et toutes les données qui s'y rapportent"},"en":{"metaTitle":"Delete my account","metaDescription":"Delete your account and all related data"}}},"test":true},
    name: "delete-account"
  }, {
    path: "/glossary",
    component: _9795617c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Lexique des terms d'escalade","metaDescription":"Le lexique communautaire des termes de l'escalade pour décoder le parler grimpeur·euse"},"en":{"metaTitle":"Glossary of climbing terms","metaDescription":"The community lexicon of climbing terms to decode the climber's language"}}}},
    name: "glossary"
  }, {
    path: "/go-fly-a-kite",
    component: _ab5a1b34,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vous êtes un robot ?","metaDescription":"Vous avez un comportement anormal, nous vous avons bloqué."},"en":{"metaTitle":"You are robot?","metaDescription":"You have an abnormal behavior, we blocked you"}}}},
    name: "go-fly-a-kite"
  }, {
    path: "/grades",
    component: _6c5954a0,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Les cotations sur Oblyk","metaDescription":"Oblyk permet plusieurs notations pour la cotations et un code couleur est associé à une difficulté, voici comment ça marche"},"en":{"metaTitle":"Grades on oblyk","metaDescription":"Oblyk allows several notations for scoring and a color code is associated with a difficulty, here is how it works"}}}},
    name: "grades"
  }, {
    path: "/gym-administrations",
    component: _994461dc,
    meta: {"middleware":["auth","admin"]},
    name: "gym-administrations"
  }, {
    path: "/home",
    component: _1bb0efc6,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mon profil Oblyk"},"en":{"metaTitle":"My Oblyk profile"}}}},
    name: "home"
  }, {
    path: "/library",
    component: _3c09e272,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Topos d'escalade","metaDescription":"Les topos de France et du monde : infos, falaises, carte des sites"},"en":{"metaTitle":"Climbing guides","metaDescription":"Guides of France and the world: information, cliffs, map of sites"}}}},
    name: "library"
  }, {
    path: "/maintenance",
    component: _20cb571f,
    meta: {"layout":"blank"},
    name: "maintenance"
  }, {
    path: "/merci",
    component: _3438304c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Merci !","metaDescription":"Un énorme merci pour votre don ! Grâce à vous, Oblyk peut continuer à se développer et envisager l'avenir plus sereinement."},"en":{"metaTitle":"Thanks !","metaDescription":"A huge thank you for your donation! Thanks to you, Oblyk can continue to develop and look forward to a brighter future."}}}},
    name: "merci"
  }, {
    path: "/new",
    component: _5ecea8af,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Enrichir Oblyk"},"en":{"metaTitle":"Enrich Oblyk"}}}},
    name: "new"
  }, {
    path: "/new-password",
    component: _071db24c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Changer mon mot de passe"},"en":{"metaTitle":"Change my password"}}}},
    name: "new-password"
  }, {
    path: "/newsletters",
    component: _26419d69,
    meta: {},
    name: "newsletters"
  }, {
    path: "/notifications",
    component: _69688e12,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes notifications"},"en":{"metaTitle":"My notifications"}}}},
    name: "notifications"
  }, {
    path: "/oblyk",
    component: _0d5ef0d7,
    meta: {"name":"OblykView","i18n":{"messages":{"fr":{"metaTitle":"Oblyk - Escalade, Carnet de croix & Carte des grimpeur·euse·s","metaDescription":"Oblyk est un site communautaire d'escalade outdoor et indoor, on peut : voir les informations des falaises, noter ses croix, trouver un partenaire d'escalade, etc."},"en":{"metaTitle":"Oblyk - Climbing community, Log book & Partner search","metaDescription":"Oblyk is an outdoor and indoor climbing community site, you can: see crag information, keep a logbook, find a climbing partner, etc."}}}},
    name: "oblyk"
  }, {
    path: "/privacy",
    component: _5feddf14,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma confidentialité"},"en":{"metaTitle":"My privacy"}}}},
    name: "privacy"
  }, {
    path: "/report-problem",
    component: _27757f2a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Signaler un problème","metaDescription":"Vous avez trouvé un problème? dit le nous pour qu'on le corrige"},"en":{"metaTitle":"Report a problem","metaDescription":"Have you found a problem? Tell us so we can fix it"}}}},
    name: "report-problem"
  }, {
    path: "/reset-password",
    component: _9f678006,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mot de passe oublié ?","metaDescription":"Vous avez oublié votre mot de passe ? renseignez votre adresse mail pour le réinitialiser"},"en":{"metaTitle":"Forgot your password?","metaDescription":"Forgot your password? Enter your email address to reset it"}}}},
    name: "reset-password"
  }, {
    path: "/sign-in",
    component: _6b52c041,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Me connecter","metaDescription":"Me connecter à mon compte Oblyk et retrouver toutes les infos de la grimpes autour de chez moi"},"en":{"metaTitle":"Connect me","metaDescription":"Connect me to my Oblyk account and find all informations about the climbing around me"}}}},
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _7fe916b7,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Me créer un compte","metaDescription":"Me créer un mon compte pour suivre ma progression, trouver des partenaire d'escalade et avoir des infos local de la communauté de la grimpe"},"en":{"metaTitle":"Create an account","metaDescription":"Create an account to track my progress, find climbing partners and get local info from the climbing community"}}}},
    name: "sign-up"
  }, {
    path: "/success-account-deleting",
    component: _1172def6,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Compte supprimé"},"en":{"metaTitle":"Account deleted"}}}},
    name: "success-account-deleting"
  }, {
    path: "/support-us",
    component: _59cef480,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Supporter Oblyk, faire un don","metaDescription":"Supporter le développement d'Oblyk pour qu'il puisse continuer à évoluer"},"en":{"metaTitle":"Support Oblyk, make a donation","metaDescription":"Support the development of Oblyk so that it can continue to evolve"}}}},
    name: "support-us"
  }, {
    path: "/about/indoor",
    component: _6ae636b9,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer le topo indoor de votre salle d'escalade","metaDescription":"Organisez votre salle d'escalade en espace et secteur, permettez à vos grimpeurs et grimpeuses de noter leur croix, supervisez vos ouvertures, et pleins d'autres fonctionnalités"},"en":{"metaTitle":"Create the indoor guidebook of your climbing gym","metaDescription":"Organize your climbing gym in space and sector, allow your climbers to rate their ascents, supervise your openings, and many other features"}}}},
    name: "about-indoor"
  }, {
    path: "/about/partner-search",
    component: _60dde7b3,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Recherche de partenaire d'escalade, comment ça marche ?","metaDescription":"La recherche de partenaire d'escalade te permet de trouver des grimpeur·euse·s autours de toi qui cherche aussi des gens pour grimper. Comment ça fonctionne ?"},"en":{"metaTitle":"Search for a climbing partner, how does it work?","metaDescription":"The climbing partner search allows you to find climbers around you who are also looking for people to climb with. How does it work?"}}}},
    name: "about-partner-search"
  }, {
    path: "/areas/new",
    component: _2fa2c4a3,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un groupe de site"},"en":{"metaTitle":"Create crags group"}}},"orphanRoute":true},
    name: "areas-new"
  }, {
    path: "/articles/new",
    component: _ef3c0c1c,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "articles-new"
  }, {
    path: "/ascents/new",
    component: _627dc1f6,
    meta: {},
    name: "ascents-new"
  }, {
    path: "/crags/new",
    component: _72cead5b,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une falaise"},"en":{"metaTitle":"Create new crag"}}},"orphanRoute":true},
    name: "crags-new"
  }, {
    path: "/crags/search",
    component: _05422c3d,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Recherche avancée de sites d'escalades : trouver des sites d'escalades en France et dans le monde","metaDescription":"Trouver exactement le site d'escalade que vous chercher, filter avec le niveau du site, l'orientation, le temps de marche, etc."},"en":{"metaTitle":"Advanced climbing crag search : find climbing crags in France and in the world","metaDescription":"Find exactly the climbing crag you are looking for, filter with the level of the crag, the orientation, the walking time, etc."}}}},
    name: "crags-search"
  }, {
    path: "/en/cookie-and-audience-measurement",
    component: _09d99cb0,
    meta: {},
    name: "en-cookie-and-audience-measurement"
  }, {
    path: "/en/terms-of-use",
    component: _ec24d76e,
    meta: {},
    name: "en-terms-of-use"
  }, {
    path: "/errors/right-required",
    component: _7b02b5e0,
    meta: {},
    name: "errors-right-required"
  }, {
    path: "/escalade-en/france",
    component: _56d0499d,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Escalade en France: falaises, topos et salles d'escalade","metaDescription":"Retrouve les informations sur les sites d'escalade en France (voie, grande voie, bloc, via ferrata ...), les topos et les salles d'escalade sur Oblyk"},"en":{"metaTitle":"Climbing in France: crags, guide books and climbing gyms","metaDescription":"Find information on climbing sites in France (routes, multi pitches, boulder, via ferrata ...), guide books and climbing gyms on Oblyk"}}}},
    name: "escalade-en-france"
  }, {
    path: "/find/climbers",
    component: _7942e333,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver un grimpeur ou une grimpeuse","metaDescription":"Trouver un ou une partenaire de grimpe avec Oblyk"},"en":{"metaTitle":"Find a climber","metaDescription":"Find a climbing partner with Oblyk"}}}},
    name: "find-climbers"
  }, {
    path: "/find/crags",
    component: _83ea68e8,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver un site d'escalade","metaDescription":"Trouver le site d'escalade que vous cherchez avec la carte des falaises du monde, ou autour d'une ville ou selon des critères bien particulier grâce à nos outils de recherche."},"en":{"metaTitle":"Find a climbing site","metaDescription":"Find the climbing crag you are looking for with the map of the world's crags, or around a city or according to specific criteria with our search tools."}}}},
    name: "find-crags"
  }, {
    path: "/find/guide-books",
    component: _280e67e7,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver le topo d'escalade que vous cherchez","metaDescription":"Chercher un topo d'escalade autour d'une ville, consulté les dernières nouveauté, fait votre topothèque, etc."},"en":{"metaTitle":"Find the climbing guidebook you are looking for","metaDescription":"Search for a climbing guide book around a city, check the latest news, make your own guidebook library, etc."}}}},
    name: "find-guide-books"
  }, {
    path: "/find/gyms",
    component: _5640f83a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver une salle d'escalade","metaDescription":"Trouver une salle d'escalade autour de votre ville grâce à la carte des salle d'escalade ou en recherchant autour d'un lieux"},"en":{"metaTitle":"Find a climbing gym","metaDescription":"Find a climbing gym around your city with the climbing gym map or by searching around a location"}}}},
    name: "find-gyms"
  }, {
    path: "/fr/conditions-d-utilisation",
    component: _05ef8dfa,
    meta: {},
    name: "fr-conditions-d-utilisation"
  }, {
    path: "/fr/cookie-et-mesure-d-audience",
    component: _9eeb450e,
    meta: {},
    name: "fr-cookie-et-mesure-d-audience"
  }, {
    path: "/guide-book-papers/find",
    component: _7adb0052,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver un topo d'escalade","metaDescription":"Trouves les topos d'escalades qu'il y a autour d'une ville ou d'un lieu en France et dans le monde"},"en":{"metaTitle":"Find a climbing guide","metaDescription":"Find the climbing topos that are around a city or a place in France and in the world"}}}},
    name: "guide-book-papers-find"
  }, {
    path: "/guide-book-papers/new",
    component: _7476c8d2,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo"},"en":{"metaTitle":"Add guide book"}}},"orphanRoute":true},
    name: "guide-book-papers-new"
  }, {
    path: "/guide-book-papers/new-versions",
    component: _18855641,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Topos intéressants","metaDescription":"Nous trouvons les topos qui pourrais t'intéresser par rapport à ton carnet de croix"},"en":{"metaTitle":"Interesting guide books","metaDescription":"We find the guide books that might interest you in relation to your log book"}}}},
    name: "guide-book-papers-new-versions"
  }, {
    path: "/guide-book-papers/recommended",
    component: _7eef2bd2,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Topos intéressants","metaDescription":"Nous trouvons les topos qui pourrais t'intéresser par rapport à ton carnet de croix"},"en":{"metaTitle":"Interesting guide books","metaDescription":"We find the guide books that might interest you in relation to your log book"}}}},
    name: "guide-book-papers-recommended"
  }, {
    path: "/guide-books/features",
    component: _6f37bd97,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Topos d'escalades et fonctionnalités","metaDescription":"Oblyk propose de nombreux outils permettant de trouver les topos, connaître les sites d'escalade qu'ils présentent, avoir une topothèque numérique, etc."},"en":{"metaTitle":"Climbing guides and features","metaDescription":"Oblyk offers many tools for finding guides, knowing the climbing crags they present, having a digital topo library, etc."}}}},
    name: "guide-books-features"
  }, {
    path: "/gyms/new",
    component: _5cd568cd,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une nouvelle salle d'escalade"},"en":{"metaTitle":"Add a new climbing gym"}}},"orphanRoute":true},
    name: "gyms-new"
  }, {
    path: "/gyms/starter",
    component: _3d919dbc,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer le topo de ma salle","metaDescription":"Créer le topo de votre salle d'escalade gratuitement en quelques minutes"},"en":{"metaTitle":"Create a guidebook of my climbing gym","metaDescription":"Create a free climbing gym guidebook in a few minutes"}}}},
    name: "gyms-starter"
  }, {
    path: "/home/climbing-sessions",
    component: _1b8e1e94,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes sessions de grimpe","metaDescription":"La liste de toutes mes sessions de grimpe"},"en":{"metaTitle":"My climbing sessions","metaDescription":"The list of all my climbing sessions"}}}},
    name: "home-climbing-sessions"
  }, {
    path: "/home/community",
    component: _5e74f546,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma communauté"},"en":{"metaTitle":"My community"}}}},
    name: "home-community",
    children: [{
      path: "followers",
      component: _6e5968e3,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes abonnées"},"en":{"metaTitle":"My followers"}}}},
      name: "home-community-followers"
    }, {
      path: "subscribes",
      component: _159ad48b,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes abonnées"},"en":{"metaTitle":"My followers"}}}},
      name: "home-community-subscribes"
    }]
  }, {
    path: "/home/favorites",
    component: _860e4e6a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes favorites"},"en":{"metaTitle":"My favorites"}}}},
    name: "home-favorites",
    children: [{
      path: "crags",
      component: _14a2030c,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes falaises"},"en":{"metaTitle":"My crags"}}}},
      name: "home-favorites-crags"
    }, {
      path: "gyms",
      component: _1618a0e8,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes salles"},"en":{"metaTitle":"My gyms"}}}},
      name: "home-favorites-gyms"
    }]
  }, {
    path: "/home/guide-books",
    component: _41f5a3d0,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Topothèque"},"en":{"metaTitle":"Library"}}}},
    name: "home-guide-books"
  }, {
    path: "/home/media",
    component: _69314450,
    meta: {"name":"CurrentUserMediaView","props":{"user":{"required":true}}},
    name: "home-media",
    children: [{
      path: "photos",
      component: _74aa10c8,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes photos"},"en":{"metaTitle":"My pictures"}}}},
      name: "home-media-photos"
    }, {
      path: "videos",
      component: _327cc01f,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes vidéos"},"en":{"metaTitle":"My videos"}}}},
      name: "home-media-videos"
    }]
  }, {
    path: "/home/messenger",
    component: _43f96b87,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma messagerie"},"en":{"metaTitle":"My messenger"}}}},
    name: "home-messenger",
    children: [{
      path: "new",
      component: _31307298,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Nouvelle conversation"},"en":{"metaTitle":"New conversation"}}}},
      name: "home-messenger-new"
    }, {
      path: ":conversationId",
      component: _3130b458,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Conversation avec %{name}"},"en":{"metaTitle":"Conversation with %{name}"}}}},
      name: "home-messenger-conversationId"
    }]
  }, {
    path: "/home/search-climbers",
    component: _c36971ec,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma recherche de partenaire"},"en":{"metaTitle":"My partner search"}}}},
    name: "home-search-climbers"
  }, {
    path: "/home/settings",
    component: _fb40eb42,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Paramètres"},"en":{"metaTitle":"Settings"}}}},
    name: "home-settings",
    children: [{
      path: "avatar",
      component: _1155c98e,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Changer mon avatar"},"en":{"metaTitle":"Change my avatar"}}}},
      name: "home-settings-avatar"
    }, {
      path: "banner",
      component: _5c4290e8,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Changer mon bandeau"},"en":{"metaTitle":"Change my banner"}}}},
      name: "home-settings-banner"
    }, {
      path: "connection",
      component: _8ec12b84,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes paramètres de connexion"},"en":{"metaTitle":"My connection parameters"}}}},
      name: "home-settings-connection"
    }, {
      path: "general",
      component: _12a284b8,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Modifier mon profil"},"en":{"metaTitle":"Edit my profile"}}}},
      name: "home-settings-general"
    }, {
      path: "notifications",
      component: _83b57290,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes paramètres de notification"},"en":{"metaTitle":"My notification parameters"}}}},
      name: "home-settings-notifications"
    }, {
      path: "others",
      component: _058ba843,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Autre paramètres"},"en":{"metaTitle":"Other parameters"}}}},
      name: "home-settings-others"
    }, {
      path: "partner",
      component: _18596898,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Ma présence sur la carte des grimpeurs"},"en":{"metaTitle":"My presence on the climbers' map"}}}},
      name: "home-settings-partner"
    }, {
      path: "privacy",
      component: _37dc4f58,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes paramètres de confidentialité"},"en":{"metaTitle":"My privacy parameters"}}}},
      name: "home-settings-privacy"
    }]
  }, {
    path: "/maps/climbers",
    component: _dde2a9d6,
    meta: {"name":"PartnerMapView","i18n":{"messages":{"fr":{"metaTitle":"La carte des grimpeur·euse·s d'Oblyk","metaDescription":"La carte des grimpeur·euse·s te permet de trouver un·e partenaire d'escalade près de chez toi. localise toi, trouve un·e grimpeur·euse, contact le·la et allez grimper ensemble !"},"en":{"metaTitle":"Map of Oblyk climbers","metaDescription":"The climber map allows you to find a climbing partner near you. Locate yourself, find a climber, contact him and go climbing together!"}}}},
    name: "maps-climbers"
  }, {
    path: "/maps/crags",
    component: _3fccdeea,
    meta: {"name":"CragMapView","i18n":{"messages":{"fr":{"metaTitle":"Carte des sites d'escalade en France et ailleurs","metaDescription":"Voir la carte interactive des sites naturels de grimpe sur Oblyk, que ce soit en France, ou dans le Monde, et voir leurs informations détaillées"},"en":{"metaTitle":"Map of climbing sites in France and elsewhere","metaDescription":"See the interactive map of natural climbing sites on Oblyk, either in France, or in the World, and see their detailed information"}}}},
    name: "maps-crags"
  }, {
    path: "/maps/guide-book-papers",
    component: _4854c9b4,
    meta: {"name":"GymMapView","i18n":{"messages":{"fr":{"metaTitle":"Carte des topos de France et ailleurs","metaDescription":"Voir la carte interactive des topos de grimpe sur Oblyk, que ce soit en France, ou dans le Monde, et voir leurs informations détaillées"},"en":{"metaTitle":"Map of climbing guide books in France and elsewhere","metaDescription":"See the interactive map of climbing guide books on Oblyk, either in France, or in the World, and see their detailed information"}}}},
    name: "maps-guide-book-papers"
  }, {
    path: "/maps/gyms",
    component: _1860011c,
    meta: {"name":"GymMapView","i18n":{"messages":{"fr":{"metaTitle":"Carte des salle d'escalade en France et ailleurs","metaDescription":"Voir la carte interactive des salle de grimpe sur Oblyk, que ce soit en France, ou dans le Monde, et voir leurs informations détaillées"},"en":{"metaTitle":"Map of climbing gyms in France and elsewhere","metaDescription":"See the interactive map of climbing gyms on Oblyk, either in France, or in the World, and see their detailed information"}}}},
    name: "maps-gyms"
  }, {
    path: "/maps/my-map",
    component: _5afed83f,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma carte"},"en":{"metaTitle":"My map"}}}},
    name: "maps-my-map"
  }, {
    path: "/newsletters/new",
    component: _70efbaf7,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "newsletters-new"
  }, {
    path: "/newsletters/subscribe",
    component: _1f53be61,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Recevoir la newsletter d'Oblyk","metaDescription":"Abonnez vous à la newsletter d'Oblyk pour recevoir de temps en temps des nouvelles de la communauté grimpante"},"en":{"metaTitle":"Receive the Oblyk newsletter","metaDescription":"Subscribe to the Oblyk newsletter to receive occasional news from the climbing community"}}}},
    name: "newsletters-subscribe"
  }, {
    path: "/newsletters/successful-subscribe",
    component: _1a74afe0,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vous êtes abonnées à la newsletter","metaDescription":"Vous êtes désormais inscrit à notre newsletter, à bientôt dans votre boîte mail"},"en":{"metaTitle":"You are subscribed to the newsletter","metaDescription":"You are now subscribed to our newsletter, see you soon in your mailbox"}}}},
    name: "newsletters-successful-subscribe"
  }, {
    path: "/newsletters/successful-unsubscribe",
    component: _53db4697,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vous n'êtes plus inscrit à la newsletter","metaDescription":"Vous être désinscrit de la newsletter, à bientôt"},"en":{"metaTitle":"You are no longer subscribed to the newsletter","metaDescription":"You are unsubscribed from the newsletter, see you soon"}}}},
    name: "newsletters-successful-unsubscribe"
  }, {
    path: "/newsletters/unsubscribe",
    component: _d585e6b0,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Se désinscrire de la newsletter","metaDescription":"Vous ne voulez plus recevoir notre newsletter, c'est ici que ça se passe"},"en":{"metaTitle":"Unsubscribe from the newsletter","metaDescription":"You don't want to receive our newsletter anymore, this is where it happens"}}}},
    name: "newsletters-unsubscribe"
  }, {
    path: "/organizations/new",
    component: _dad56286,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Enregistrer mon organisation"},"en":{"metaTitle":"Register my organization"}}},"orphanRoute":true},
    name: "organizations-new"
  }, {
    path: "/words/new",
    component: _0449cac6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Nouvelle définition"},"en":{"metaTitle":"New definition"}}},"orphanRoute":true},
    name: "words-new"
  }, {
    path: "/ascents/indoor/new",
    component: _2adc54fe,
    meta: {},
    name: "ascents-indoor-new"
  }, {
    path: "/ascents/outdoor/new",
    component: _2e5920e3,
    meta: {"orphanRoute":true},
    name: "ascents-outdoor-new"
  }, {
    path: "/home/ascents/indoor",
    component: _ae20fd70,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes croix indoor"},"en":{"metaTitle":"My indoor ascents"}}}},
    name: "home-ascents-indoor"
  }, {
    path: "/home/ascents/outdoor",
    component: _4b59e8ba,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes croix outdoor"},"en":{"metaTitle":"My outdoor ascents"}}}},
    children: [{
      path: "",
      component: _65410886,
      meta: {"name":"CurrentUserSendListView","props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes croix outdoor"},"en":{"metaTitle":"My outdoor ascents"}}}},
      name: "home-ascents-outdoor"
    }, {
      path: "analytiks",
      component: _e683502e,
      meta: {"name":"CurrentUserAnalytiksView","props":{"user":{"required":true}}},
      name: "home-ascents-outdoor-analytiks"
    }, {
      path: "projects",
      component: _ba788042,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes projets"},"en":{"metaTitle":"My projects"}}}},
      name: "home-ascents-outdoor-projects"
    }, {
      path: "tick-list",
      component: _19da0e39,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Ma tick-list"},"en":{"metaTitle":"My tick-list"}}}},
      name: "home-ascents-outdoor-tick-list"
    }]
  }, {
    path: "/home/guide-books/add",
    component: _587e959f,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo"},"en":{"metaTitle":"Add guide book"}}}},
    name: "home-guide-books-add"
  }, {
    path: "/",
    component: _ac924744,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Oblyk - Escalade, Carnet de croix & Carte des grimpeur·euse·s","metaDescription":"Oblyk est un site communautaire d'escalade outdoor et indoor, on peut : voir les informations des falaises, noter ses croix, trouver un partenaire d'escalade, etc."},"en":{"metaTitle":"Oblyk - Climbing community, Log book & Partner search","metaDescription":"Oblyk is an outdoor and indoor climbing community site, you can: see crag information, keep a logbook, find a climbing partner, etc."}}}},
    name: "index"
  }, {
    path: "/home/climbing-sessions/:sessionDate",
    component: _cfbb02d4,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma session de grimpe du %{date}","metaDescription":"Les croix, lieux, grimpeurs et grimpeuses avec qui j'ai grimpé le %{date}"},"en":{"metaTitle":"My climbing session on %{date}","metaDescription":"My ascents, places, climbers with whom I climbed on %{date}"}}}},
    name: "home-climbing-sessions-sessionDate"
  }, {
    path: "/escalade-en/france/:departmentNumber?/:departementName",
    component: _57fbd998,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Escalade %{name} : falaises, topos et salles d'escalade","metaDescription":"Retrouve les informations sur les sites d'escalade %{name} (voie, grande voie, bloc, via ferrata ...), les topos et les salles d'escalade sur Oblyk"},"en":{"metaTitle":"Climbing %{name} : crags, guide book and climbing gyms","metaDescription":"Find information on climbing sites %{name} (routes, multi pitches, boulder, via ferrata ...), guide books and climbing gyms on Oblyk"}}}},
    name: "escalade-en-france-departmentNumber-departementName"
  }, {
    path: "/climbers/:userName?",
    component: _44da840a,
    meta: {},
    children: [{
      path: "",
      component: _594cc784,
      meta: {"name":"UserProfileView","props":{"user":{"required":true}}},
      name: "climbers-userName"
    }, {
      path: "ascents",
      component: _539361c5,
      meta: {"props":{"user":{"required":true}}},
      name: "climbers-userName-ascents",
      children: [{
        path: "indoor",
        component: _834ce5e6,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les croix outdoor de %{name}","metaDescription":"Consulter les croix outdoor faites par %{name} tout au long de sa vie de grimpeur·euse"},"en":{"metaTitle":"The outdoor ascents of %{name}","metaDescription":"Consult the outdoor ascents made by %{name} throughout his climbing life"}}}},
        name: "climbers-userName-ascents-indoor"
      }, {
        path: "outdoor",
        component: _044f5f32,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les croix outdoor de %{name}","metaDescription":"Consulter les croix outdoor faites par %{name} tout au long de sa vie de grimpeur·euse"},"en":{"metaTitle":"The outdoor ascents of %{name}","metaDescription":"Consult the outdoor ascents made by %{name} throughout his climbing life"}}}},
        name: "climbers-userName-ascents-outdoor"
      }]
    }, {
      path: "followers",
      component: _e9b217fe,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les abonnés de %{name}","metaDescription":"Voir les grimpeur·euse·s abonnées à %{name}, grimpeur·euse de la communauté Oblyk"},"en":{"metaTitle":"The subscribers of %{name}","metaDescription":"See the climbers subscribed to %{name}, climber of the community Oblyk"}}}},
      name: "climbers-userName-followers"
    }, {
      path: "media",
      component: _f9dfeb60,
      meta: {"props":{"user":{"required":true}}},
      name: "climbers-userName-media",
      children: [{
        path: "photos",
        component: _4bb4ed50,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Photos de %{name}","metaDescription":"Voir les photos d'escalade prise par %{name} sur les différentes falaises qu'iel a parcourut"},"en":{"metaTitle":"Photos of %{name}","metaDescription":"See the climbing pictures taken by %{name} on the different cliffs he climbed"}}}},
        name: "climbers-userName-media-photos"
      }, {
        path: "videos",
        component: _09879ca7,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Videos de %{name}","metaDescription":"Voir les vidéos d'escalade prise par %{name} sur les différentes falaises qu'iel a parcourut"},"en":{"metaTitle":"Videos of %{name}","metaDescription":"See the climbing videos taken by %{name} on the different cliffs he climbed"}}}},
        name: "climbers-userName-media-videos"
      }]
    }, {
      path: "subscribes",
      component: _db02a5a6,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Abonnement de %{name}","metaDescription":"Voir les abonnements aux falaises, salles, et autre grimpeur·euse de %{name}"},"en":{"metaTitle":"Subscribe of %{name}","metaDescription":"View memberships to cliffs, gyms, and other climbers in %{name}"}}}},
      name: "climbers-userName-subscribes"
    }]
  }, {
    path: "/comments/:commentId",
    component: _6db234e5,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Commentaire"},"en":{"metaTitle":"Comment"}}}},
    name: "comments-commentId"
  }, {
    path: "/escalade-autour-de/:townName",
    component: _fde4606a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Escalade %{name} : falaises, topos et salles d'escalade","metaDescription":"Retrouve les informations sur les sites d'escalade autour de %{name} (%{department_number}) : voie, grande voie, bloc, via ferrata ..., les topos et les salles d'escalade sur Oblyk"},"en":{"metaTitle":"Climbing %{name} : crags, guide book and climbing gyms","metaDescription":"Find information on climbing sites around %{name} (%{department_number}) : routes, multi pitches, boulder, via ferrata ..., guide books and climbing gyms on Oblyk"}}}},
    name: "escalade-autour-de-townName"
  }, {
    path: "/gr/:gymAndRouteId",
    component: _558566fc,
    meta: {},
    name: "gr-gymAndRouteId"
  }, {
    path: "/grs/:gymId",
    component: _55652536,
    meta: {},
    name: "grs-gymId"
  }, {
    path: "/gym-chains/:gymChainId?",
    component: _4471bc59,
    meta: {},
    children: [{
      path: "",
      component: _11e847c8,
      meta: {"props":{"gymChain":{"required":true}}},
      name: "gym-chains-gymChainId"
    }]
  }, {
    path: "/photos/:photoId",
    component: _764bdcea,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Photo"},"en":{"metaTitle":"Photo"}}}},
    name: "photos-photoId"
  }, {
    path: "/videos/:videoId",
    component: _75269a1d,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vidéo"},"en":{"metaTitle":"Video"}}}},
    name: "videos-videoId"
  }, {
    path: "/alerts/:alertId?/edit",
    component: _3f09a8e6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier l'alerte"},"en":{"metaTitle":"Edit alert"}}},"orphanRoute":true},
    name: "alerts-alertId-edit"
  }, {
    path: "/authors/:authorId?/cover",
    component: _04089f52,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une couverture à l'auteur"},"en":{"metaTitle":"Add author cover"}}},"orphanRoute":true},
    name: "authors-authorId-cover"
  }, {
    path: "/authors/:authorId?/edit",
    component: _7485a2bf,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "authors-authorId-edit"
  }, {
    path: "/comments/:commentId?/edit",
    component: _09ad3dc7,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le commentaire"},"en":{"metaTitle":"Edit comment"}}},"orphanRoute":true},
    name: "comments-commentId-edit"
  }, {
    path: "/guide-book-pdfs/:guideBookPdfId?/edit",
    component: _2ff51291,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le topo PDF"},"en":{"metaTitle":"Edit PDF guide"}}},"orphanRoute":true},
    name: "guide-book-pdfs-guideBookPdfId-edit"
  }, {
    path: "/guide-book-webs/:guideBookWebId?/edit",
    component: _597e0ad1,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le topo web"},"en":{"metaTitle":"Edit web guide"}}},"orphanRoute":true},
    name: "guide-book-webs-guideBookWebId-edit"
  }, {
    path: "/links/:linkId?/edit",
    component: _68ebacc2,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "links-linkId-edit"
  }, {
    path: "/areas/:areaId?/:areaName?",
    component: _9c83641c,
    meta: {},
    children: [{
      path: "",
      component: _408116f5,
      meta: {"props":{"area":{"required":true}}},
      name: "areas-areaId-areaName"
    }, {
      path: "crags",
      component: _6a488b3e,
      meta: {"props":{"area":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les falaises de %{name}, Groupe de sites d'escalade","metaDescription":"Voir les falaises du groupe de sites d'escalade de %{name}, quels spots de grimpe il présente ou encore quelle variété de cotations on y trouve"},"en":{"metaTitle":"Crags of %{name}, climbing crags group","metaDescription":"See the crags of the group of climbing sites of %{name}, what kind of climbing spots it offers or what variety of grades can be found there"}}}},
      name: "areas-areaId-areaName-crags"
    }, {
      path: "photos",
      component: _eef8a124,
      meta: {"props":{"area":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, Groupe de sites d'escalade","metaDescription":"Voir les photos du groupe de sites d'escalade de %{name}, quels spots de grimpe il présente ou encore quelle variété de cotations on y trouve"},"en":{"metaTitle":"Pictures of %{name}, climbing crags group","metaDescription":"See the pictures of the group of climbing sites of %{name}, what kind of climbing spots it offers or what variety of grades can be found there"}}}},
      name: "areas-areaId-areaName-photos"
    }]
  }, {
    path: "/articles/:articleId/:articleName",
    component: _e83d0798,
    meta: {},
    name: "articles-articleId-articleName"
  }, {
    path: "/crag-routes/:cragRouteId?/:cragRouteName",
    component: _0d1b1d3e,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"%{name} %{grade}, %{cragName}","metaDescription":"%{name} %{grade}, %{type} faisant partie du site d'escalade %{cragName}. voir le detail de la ligne : cotation, photo, avis, etc."},"en":{"metaTitle":"%{name} %{grade}, %{cragName}","metaDescription":"%{name} %{grade}, %{type} forming part of the climbing site %{cragName}. see the details of the line: grade, photo, opinion, etc."}}}},
    name: "crag-routes-cragRouteId-cragRouteName"
  }, {
    path: "/crag-sectors/:cragSectorId?/:cragSectorName?",
    component: _432ca096,
    meta: {},
    children: [{
      path: "",
      component: _ee824b10,
      meta: {"props":{"cragSector":{"required":true}}},
      name: "crag-sectors-cragSectorId-cragSectorName"
    }, {
      path: "maps",
      component: _74b6a3e1,
      meta: {"name":"CragSectorMapView","props":{"cragSector":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"La carte de %{name}, secteur d'escalade de %{crag}","metaDescription":"La carte de  %{name}, secteur d'escalade %{crag} situé à %{city} en %{region}."},"en":{"metaTitle":"Map of %{name}, climbing sector of %{crag}","metaDescription":"Map of %{name}, climbing sector of %{crag} located at %{city} in %{region}"}}}},
      name: "crag-sectors-cragSectorId-cragSectorName-maps"
    }, {
      path: "photos",
      component: _47fe5c4b,
      meta: {"name":"CragSectorPhotosView","props":{"cragSector":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, secteur d'escalade de %{crag}","metaDescription":"Les photos de  %{name}, secteur d'escalade %{crag} situé à %{city} en %{region}."},"en":{"metaTitle":"Pictures of %{name}, climbing sector of %{crag}","metaDescription":"Pictures of %{name}, climbing sector of %{crag} located at %{city} in %{region}"}}}},
      name: "crag-sectors-cragSectorId-cragSectorName-photos"
    }]
  }, {
    path: "/crags/:cragId?/:cragName?",
    component: _54780d3a,
    meta: {},
    children: [{
      path: "",
      component: _491f7f86,
      meta: {"props":{"crag":{"required":true}}},
      name: "crags-cragId-cragName"
    }, {
      path: "guide-books",
      component: _d91d9378,
      meta: {"name":"CragGuideBooksView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les topos de %{name}, escalade en %{region}","metaDescription":"Les topos de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Climbing guide book of %{name}, climb in %{region}","metaDescription":"Climbing guide book of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-guide-books"
    }, {
      path: "links",
      component: _a866c8b8,
      meta: {"name":"CragLinkView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les liens de %{name}, escalade en %{region}","metaDescription":"Les liens de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Links of %{name}, climb in %{region}","metaDescription":"Links of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-links"
    }, {
      path: "maps",
      component: _1ba76aff,
      meta: {"name":"CragMapDetailsView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"La carte de %{name}, escalade en %{region}","metaDescription":"La carte de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Map of %{name}, climb in %{region}","metaDescription":"Map of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-maps"
    }, {
      path: "photos",
      component: _4b79af26,
      meta: {"name":"CragPhotosView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, escalade en %{region}","metaDescription":"Les photos de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Pictures of %{name}, climb in %{region}","metaDescription":"Pictures of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-photos"
    }, {
      path: "routes",
      component: _d8ccae9c,
      meta: {"props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les voies de %{name}, site d'escalade en %{region}","metaDescription":"Retrouvez toutes les voies et blocs du site d'escalade de %{name}, les cotations et les avis des grimpeurs"},"en":{"metaTitle":"The routes of %{name}, climbing site in %{region}","metaDescription":"Find all the routes and boulders of the %{name} climbing site, the quotations and the climbers' opinions"}}}},
      name: "crags-cragId-cragName-routes"
    }]
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?",
    component: _5b3fa25a,
    meta: {},
    children: [{
      path: "",
      component: _0e3b7d5d,
      meta: {"props":{"guideBookPaper":{"required":true}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName"
    }, {
      path: "alternatives",
      component: _905a48aa,
      meta: {"props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Topos alternatifs à %{name}","metaDescription":"Liste des autres topos d'escalades qui traitent des mêmes sites que le topo %{name}"},"en":{"metaTitle":"Alternative guides to %{name}","metaDescription":"List of other climbing guidebooks that deal with the same crags as the guidebook %{name}"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-alternatives"
    }, {
      path: "crags",
      component: _ced3be6e,
      meta: {"name":"GuideBookPaperCragsView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les falaises de %{name}, topo d'escalade","metaDescription":"Voir les falaises du topo d'escalade %{name}."},"en":{"metaTitle":"Crags of %{name}, climbing guide book","metaDescription":"See the crags of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-crags"
    }, {
      path: "links",
      component: _42d04e78,
      meta: {"name":"GuideBookPaperLinkView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les liens de %{name}, topo d'escalade","metaDescription":"Voir les liens du topo d'escalade %{name}."},"en":{"metaTitle":"Links of %{name}, climbing guide book","metaDescription":"See the links of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-links"
    }, {
      path: "map",
      component: _042674b2,
      meta: {"name":"GuideBookPaperMapView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"La carte de %{name}, topo d'escalade","metaDescription":"Voir la carte du topo d'escalade %{name}."},"en":{"metaTitle":"Map of %{name}, climbing guide book","metaDescription":"See the map of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-map"
    }, {
      path: "photos",
      component: _72161606,
      meta: {"name":"GuideBookPaperPhotosView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, topo d'escalade","metaDescription":"Voir les photos du topo d'escalade %{name}."},"en":{"metaTitle":"Pictures of %{name}, climbing guide book","metaDescription":"See the pictures of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-photos"
    }, {
      path: "points-of-sale",
      component: _2ac18718,
      meta: {"props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les points de vente de %{name}, topo d'escalade","metaDescription":"Voir les points de vente du topo d'escalade %{name}."},"en":{"metaTitle":"Points of sale of %{name}, climbing guide book","metaDescription":"See the Points of sale of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-points-of-sale"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?",
    component: _509fe5ac,
    meta: {},
    children: [{
      path: "",
      component: _a24709a2,
      meta: {"props":{"gym":{"required":true}}},
      name: "gyms-gymId-gymName"
    }, {
      path: "followers",
      component: _1b4ef272,
      meta: {"props":{"gym":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les abonnés de %{name}","metaDescription":"Voir les grimpeurs et grimpeuses abonné·es à la salle d'escalade %{name}"},"en":{"metaTitle":"The subscribers of %{name}","metaDescription":"See the climbers subscribed to %{name} climbing gym"}}}},
      name: "gyms-gymId-gymName-followers"
    }]
  }, {
    path: "/newsletters/:newsletterId/:newsletterName",
    component: _d2e544be,
    meta: {"i18n":{"messages":{"fr":{"metaDescription":"Consulté ici la newsletter que vous avez reçu dans votre boîte email"},"en":{"metaDescription":"Consulted here the newsletter you received in your email box"}}}},
    name: "newsletters-newsletterId-newsletterName"
  }, {
    path: "/organizations/:organizationId?/:organizationName?",
    component: _74cb3850,
    meta: {},
    children: [{
      path: "",
      component: _6912a85b,
      meta: {"props":{"organization":{"required":true}}},
      name: "organizations-organizationId-organizationName"
    }]
  }, {
    path: "/words/:wordId?/:slugName",
    component: _18bdf41a,
    meta: {},
    name: "words-wordId-slugName"
  }, {
    path: "/alerts/:alertableType?/:alertableId?/new",
    component: _3f1a31a6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Créer une alerte"},"en":{"metaTitle":"Create alert"}}},"orphanRoute":true},
    name: "alerts-alertableType-alertableId-new"
  }, {
    path: "/articles/:articleId/:articleName?/add-crags",
    component: _58cef074,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-add-crags"
  }, {
    path: "/articles/:articleId/:articleName?/add-guide-books",
    component: _1e658262,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-add-guide-books"
  }, {
    path: "/articles/:articleId/:articleName?/cover",
    component: _3203ac99,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-cover"
  }, {
    path: "/articles/:articleId/:articleName?/edit",
    component: _1c3f5ed0,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-edit"
  }, {
    path: "/articles/:articleId/:articleName?/photos",
    component: _ada01be2,
    meta: {},
    name: "articles-articleId-articleName-photos"
  }, {
    path: "/comments/:commentableType?/:commentableId?/new",
    component: _0e05b92c,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un commentaire"},"en":{"metaTitle":"Add comment"}}},"orphanRoute":true},
    name: "comments-commentableType-commentableId-new"
  }, {
    path: "/contests/:gymId/:contestId?/print-results",
    component: _2365bd23,
    meta: {"layout":"blank"},
    name: "contests-gymId-contestId-print-results"
  }, {
    path: "/links/:linkableType?/:linkableId?/new",
    component: _1040141a,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un nouveau lien"},"en":{"metaTitle":"Add a new link"}}},"orphanRoute":true},
    name: "links-linkableType-linkableId-new"
  }, {
    path: "/newsletters/:newsletterId/:newsletterName?/edit",
    component: _7ea7e4ea,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "newsletters-newsletterId-newsletterName-edit"
  }, {
    path: "/newsletters/:newsletterId/:newsletterName?/photos",
    component: _17ff837c,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "newsletters-newsletterId-newsletterName-photos"
  }, {
    path: "/photos/:illustrableType?/:illustrableId?/new",
    component: _7f45f8b7,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une photo"},"en":{"metaTitle":"Add a photo"}}}},
    name: "photos-illustrableType-illustrableId-new"
  }, {
    path: "/reports/:reportableType?/:reportableId?/new",
    component: _19c053ed,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Signaler un problème","metaDescription":"Vous avez trouvé un problème? dit le nous pour qu'on le corrige"},"en":{"metaTitle":"Report a problem","metaDescription":"Have you found a problem? Tell us so we can fix it"}}}},
    name: "reports-reportableType-reportableId-new"
  }, {
    path: "/versions/:versionType?/:versionId?/changes",
    component: _51256c14,
    meta: {"name":"VersionsView"},
    name: "versions-versionType-versionId-changes"
  }, {
    path: "/videos/:viewableType?/:viewableId?/new",
    component: _fc2a4c00,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une video"},"en":{"metaTitle":"Add a video"}}},"orphanRoute":true},
    name: "videos-viewableType-viewableId-new"
  }, {
    path: "/words/:wordId?/:slugName?/edit",
    component: _158dda0e,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "words-wordId-slugName-edit"
  }, {
    path: "/photos/:illustrableType?/:illustrableId?/:photoId?/edit",
    component: _b22b3ebc,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier la photo"},"en":{"metaTitle":"Edit photo"}}}},
    name: "photos-illustrableType-illustrableId-photoId-edit"
  }, {
    path: "/videos/:viewableType?/:viewableId?/:videoId?/edit",
    component: _17f28cc2,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier la video"},"en":{"metaTitle":"Edit video"}}}},
    name: "videos-viewableType-viewableId-videoId-edit"
  }, {
    path: "/gym-chains/:gymChainId?/banner",
    component: _f9ed8f5c,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le bandeau"},"en":{"metaTitle":"Edit banner"}}},"orphanRoute":true},
    name: "gym-chains-gymChainId-banner"
  }, {
    path: "/gym-chains/:gymChainId?/edit",
    component: _797653b0,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "gym-chains-gymChainId-edit"
  }, {
    path: "/gym-chains/:gymChainId?/logo",
    component: _da5a1a5e,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le logo"},"en":{"metaTitle":"Edit logo"}}},"orphanRoute":true},
    name: "gym-chains-gymChainId-logo"
  }, {
    path: "/areas/:areaId?/:areaName?/add-crag",
    component: _53d6e42e,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un site à %{name}"},"en":{"metaTitle":"Add crag in %{name}"}}},"orphanRoute":true},
    name: "areas-areaId-areaName-add-crag"
  }, {
    path: "/areas/:areaId?/:areaName?/edit",
    component: _211c4c92,
    meta: {"orphanRoute":true},
    name: "areas-areaId-areaName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/add-on-area",
    component: _3369e93a,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter %{name} à une zone"},"en":{"metaTitle":"Add %{name} in area"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-add-on-area"
  }, {
    path: "/crags/:cragId?/:cragName?/add-on-guide-book",
    component: _0fe12587,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo à %{name}"},"en":{"metaTitle":"Add paper guide at %{name}"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-add-on-guide-book"
  }, {
    path: "/crags/:cragId?/:cragName?/edit",
    component: _fe573122,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/new-pdf-guide-book",
    component: _61790147,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo PDF"},"en":{"metaTitle":"Add PDF guide"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-new-pdf-guide-book"
  }, {
    path: "/crags/:cragId?/:cragName?/new-web-guide-book",
    component: _e84ed2b6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo web"},"en":{"metaTitle":"Add web guide"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-new-web-guide-book"
  }, {
    path: "/crags/:cragId?/:cragName?/approaches/new",
    component: _7ecea5e8,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer une approche"},"en":{"metaTitle":"Create approach"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-approaches-new"
  }, {
    path: "/crags/:cragId?/:cragName?/maps/edit",
    component: _178bdc6d,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-maps-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/parks/new",
    component: _24d3f376,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un parking"},"en":{"metaTitle":"Add a park"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-parks-new"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/new",
    component: _1e340480,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une ligne"},"en":{"metaTitle":"Add crag route"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-routes-new"
  }, {
    path: "/crags/:cragId?/:cragName?/sectors/new",
    component: _4c1514ee,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un secteur"},"en":{"metaTitle":"Add sector"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-sectors-new"
  }, {
    path: "/crags/:cragId?/:cragName?/approaches/:approachId?/edit",
    component: _82e3eb8a,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier l'approche"},"en":{"metaTitle":"Edit approach"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-approaches-approachId-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/parks/:parkId?/edit",
    component: _4c0353c0,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le parking"},"en":{"metaTitle":"Edit park"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-parks-parkId-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/:cragRouteId/:cragRouteName?/edit",
    component: _f74ac76c,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-routes-cragRouteId-cragRouteName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/sectors/:cragSectorId?/:cragSectorName?/edit",
    component: _41027af5,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-sectors-cragSectorId-cragSectorName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/:cragRouteId/:cragRouteName?/ascents/new",
    component: _7767315a,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter à mon carnet"},"en":{"metaTitle":"Add to my logbook"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-routes-cragRouteId-cragRouteName-ascents-new"
  }, {
    path: "/crags/:cragId?/:cragName?/sectors/:cragSectorId?/:cragSectorName?/routes/new",
    component: _4acadb86,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une ligne"},"en":{"metaTitle":"Add crag route"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-sectors-cragSectorId-cragSectorName-routes-new"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/:cragRouteId/:cragRouteName?/ascents/:ascentCragRouteId?/edit",
    component: _7985e6bd,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier ma croix"},"en":{"metaTitle":"Edit my ascent"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-routes-cragRouteId-cragRouteName-ascents-ascentCragRouteId-edit"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/add-crag",
    component: _226a04c6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un site à %{name}"},"en":{"metaTitle":"Add crag in %{name}"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-add-crag"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/cover",
    component: _9744a47c,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer la couverture de %{name}"},"en":{"metaTitle":"Change cover of %{name}"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-cover"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/edit",
    component: _171d064f,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-edit"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/place-of-sales/new",
    component: _61ac04b2,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un point de vente"},"en":{"metaTitle":"Add a place of sale"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-place-of-sales-new"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/place-of-sales/:placeOfSaleId?/edit",
    component: _e21c246c,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le point de vente"},"en":{"metaTitle":"Edit place of sale"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-place-of-sales-placeOfSaleId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/administration-request",
    component: _27b96493,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Demande d'administration de %{name}","metaDescription":"Administrez la salle d'escalade de %{name} pour créer votre topo indoor et animer votre communauté"},"en":{"metaTitle":"Request for administration of %{name}","metaDescription":"Manage the %{name} climbing gym to create your indoor topo and animate your community"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-administration-request"
  }, {
    path: "/gyms/:gymId?/:gymName?/administrator-required",
    component: _5957b792,
    meta: {"props":{"gym":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Administrateur requis"},"en":{"metaTitle":"Required administrator"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-administrator-required"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins",
    component: _b572ee8c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"%{name} - Dashboard"},"en":{"metaTitle":"%{name} - Dashboard"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins"
  }, {
    path: "/gyms/:gymId?/:gymName?/banner",
    component: _756708c2,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le bandeau"},"en":{"metaTitle":"Edit banner"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-banner"
  }, {
    path: "/gyms/:gymId?/:gymName?/edit",
    component: _5e5c063d,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "gyms-gymId-gymName-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/logo",
    component: _77b8a55e,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le logo"},"en":{"metaTitle":"Edit logo"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-logo"
  }, {
    path: "/gyms/:gymId?/:gymName?/ranking",
    component: _6a9aa15a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Le classement de %{name}","metaDescription":"Voir le classement des grimpeurs et grimpeuses de la salle d'escalade %{name}"},"en":{"metaTitle":"%{name}'s ranking","metaDescription":"See the ranking of climbers in the climbing gym %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-ranking"
  }, {
    path: "/gyms/:gymId?/:gymName?/select-space",
    component: _e32026b0,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Choisissez un espace"},"en":{"metaTitle":"Choose a space"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-select-space"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces",
    component: _451ab2a3,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/administrators",
    component: _310662de,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les administrateurs"},"en":{"metaTitle":"Administrators"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-administrators"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/championships",
    component: _2c4b1a4e,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les championnats"},"en":{"metaTitle":"Championships"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-championships"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/climbing-styles",
    component: _457d5c8d,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Style d'escalade"},"en":{"metaTitle":"Climbing styles"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-climbing-styles"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/comments",
    component: _6cd0d082,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les commentaires"},"en":{"metaTitle":"Comments"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-comments"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/contests",
    component: _6e8f042c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les contests"},"en":{"metaTitle":"Contests"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-contests"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/first-difficulty-system",
    component: _743374e8,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Premier système de difficulté"},"en":{"metaTitle":"First difficulty system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-first-difficulty-system"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/first-space",
    component: _b32197de,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer le premier espace de %{name}","metaDescription":"commencez à créer votre topo d'escalade indoor en créant le première espace de %{name}"},"en":{"metaTitle":"Create the first space for %{name}","metaDescription":"Start creating your indoor climbing map by creating the first %{name} space"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-first-space"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades",
    component: _095ed747,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les systèmes de difficultés"},"en":{"metaTitle":"Difficulty systems"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/indoor-subscriptions",
    component: _385e6672,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes abonnements"},"en":{"metaTitle":"My subscriptions"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-indoor-subscriptions"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates",
    component: _56afa90b,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modèle d'étiquettes"},"en":{"metaTitle":"Label templates"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/levels",
    component: _e6b8450c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Couleurs et cotations"},"en":{"metaTitle":"Colors and grades"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-levels"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/openers",
    component: _233e6147,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les ouvreurs"},"en":{"metaTitle":"Openers"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-openers"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets",
    component: _14775ed8,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les fiches d'ouvertures"},"en":{"metaTitle":"Opening sheets"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/ranking-systems",
    component: _cf166290,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"System de classement"},"en":{"metaTitle":"Ranking system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-ranking-systems"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/tree-structures",
    component: _76771057,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"La structure"},"en":{"metaTitle":"Structure"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-tree-structures"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/videos",
    component: _0d7e17ba,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les vidéos"},"en":{"metaTitle":"Videos"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-videos"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/new",
    component: _77a0d6b1,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer un nouvel espace"},"en":{"metaTitle":"Create a new space"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/administrators/new",
    component: _33d5d3c2,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un administrateur"},"en":{"metaTitle":"Add administrator"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-administrators-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/championships/new",
    component: _3ac1a732,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un championnat"},"en":{"metaTitle":"Create championship"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-championships-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/contests/new",
    component: _0e127090,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un contest"},"en":{"metaTitle":"Create contest"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-contests-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/new",
    component: _29a45756,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Nouveau system de cotation"},"en":{"metaTitle":"New grade system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates/new",
    component: _915f71ce,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un modèle d'étiquette"},"en":{"metaTitle":"Create label template"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/openers/new",
    component: _2b67de55,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un ouvreur"},"en":{"metaTitle":"Add opener"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-openers-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets/new",
    component: _2d69863c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer une fiche d'ouverture"},"en":{"metaTitle":"Create opening sheet"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/routes/statistics",
    component: _1538b5f0,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"%{name} - Statistique"},"en":{"metaTitle":"%{name} - Statistic"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-routes-statistics"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/routes/tables",
    component: _d4ea169c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"%{name} - Gestion des ouvertures"},"en":{"metaTitle":"%{name} - Routes management"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-routes-tables"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/space-groups/new",
    component: _57e4e654,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un group"},"en":{"metaTitle":"Create group"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-space-groups-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/spaces/edit-three-d",
    component: _aa92ce88,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-spaces-edit-three-d"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/three-d-assets/new",
    component: _44fc4e34,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Importer une décoration"},"en":{"metaTitle":"Import asset"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-three-d-assets-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/championships/:championshipId",
    component: _89735df4,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    children: [{
      path: "",
      component: _4e9109ee,
      meta: {"middleware":["auth","gymAdmin"],"props":{"championship":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-championships-championshipId"
    }, {
      path: "results",
      component: _58adb06d,
      meta: {"middleware":["auth","gymAdmin"],"props":{"championship":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-championships-championshipId-results"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/contests/:contestId",
    component: _8edd3a30,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    children: [{
      path: "",
      component: _6ef21b6b,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId"
    }, {
      path: "banner",
      component: _07eae2e3,
      meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Illustration du contest"},"en":{"metaTitle":"Contest picture"}}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId-banner"
    }, {
      path: "edit",
      component: _0d9922fe,
      meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un contest"},"en":{"metaTitle":"Create contest"}}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId-edit"
    }, {
      path: "participants",
      component: _50f3ee17,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-admins-contests-contestId-participants"
    }, {
      path: "results",
      component: _4ac1c04f,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-admins-contests-contestId-results"
    }, {
      path: "time-line",
      component: _7a73e00d,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true},"editContestModal":{"default":null}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId-time-line"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId",
    component: _6d15ffae,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates/:gymLabelTemplateId",
    component: _a96c50ae,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Étiquette : %{name}"},"en":{"metaTitle":"Label : %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates-gymLabelTemplateId"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets/:gymOpeningSheetId",
    component: _499944a2,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets-gymOpeningSheetId"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/administrators/:gymAdministratorId/edit",
    component: _0241a2c4,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le system de cotation"},"en":{"metaTitle":"Edit grade system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-administrators-gymAdministratorId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId/edit",
    component: _2ab075de,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le system de cotation"},"en":{"metaTitle":"Edit grade system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates/:gymLabelTemplateId?/print",
    component: _704ffc04,
    meta: {"layout":"print","orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates-gymLabelTemplateId-print"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/openers/:gymOpenerId/edit",
    component: _30f19ee0,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier %{name}"},"en":{"metaTitle":"Edit %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-openers-gymOpenerId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets/:gymOpeningSheetId?/print",
    component: _bf8cfbec,
    meta: {"layout":"print","orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets-gymOpeningSheetId-print"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/space-groups/:gymSpaceGroupId?/edit",
    component: _46c1ba86,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier %{name}"},"en":{"metaTitle":"Edit %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-space-groups-gymSpaceGroupId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/spaces/:gymSpaceId?/edit-three-d",
    component: _6ee55a66,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-spaces-gymSpaceId-edit-three-d"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/spaces/:gymSpaceId?/take-three-d-picture",
    component: _0fa1dbd8,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-spaces-gymSpaceId-take-three-d-picture"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/three-d-assets/:threeDAssetId?/edit",
    component: _171164a7,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Éditer la décoration"},"en":{"metaTitle":"Edit asset"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-three-d-assets-threeDAssetId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId/grade-lines/new",
    component: _0dba2414,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Nouveau niveau"},"en":{"metaTitle":"New level"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId-grade-lines-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId/grade-lines/:gymGradeLineId?/edit",
    component: _3028746e,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le niveau"},"en":{"metaTitle":"Edit level"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId-grade-lines-gymGradeLineId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/contests/rankers/:contestId?/:contestName",
    component: _0bce15d0,
    meta: {"layout":"contest","channels":{"ContestRankersChannel":{}},"orphanRoute":true},
    name: "gyms-gymId-gymName-contests-rankers-contestId-contestName"
  }, {
    path: "/gyms/:gymId?/:gymName?/championships/:championshipId?/:championshipName",
    component: _d0929744,
    meta: {"layout":"contest","orphanRoute":true},
    name: "gyms-gymId-gymName-championships-championshipId-championshipName"
  }, {
    path: "/gyms/:gymId?/:gymName?/contests/:contestId?/:contestName?",
    component: _01506a3c,
    meta: {"layout":"contest","orphanRoute":true},
    children: [{
      path: "",
      component: _53e51fe5,
      meta: {"layout":"contest","props":{"contest":{"required":true},"updateToken":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-contests-contestId-contestName"
    }, {
      path: "my-contest",
      component: _311e7270,
      meta: {"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-contests-contestId-contestName-my-contest"
    }, {
      path: "results",
      component: _81f4e36e,
      meta: {"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-contests-contestId-contestName-results"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName",
    component: _76a61a02,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/edit",
    component: _18955726,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/select-sector",
    component: _554a57f8,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Choisissez un secteur"},"en":{"metaTitle":"Choose a sector"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-select-sector"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/upload-3d",
    component: _6f94500a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Modifier le 3D"},"en":{"metaTitle":"Modify the 3D"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-upload-3d"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/upload-plan",
    component: _cbdf173c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Modifier le plan de fond"},"en":{"metaTitle":"Modify the background schema"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-upload-plan"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/sectors/new",
    component: _11dd8ecb,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un secteur"},"en":{"metaTitle":"Add a sector"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-sectors-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/routes/:gymRouteId?/edit",
    component: _2e01dc23,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Modifier la ligne"},"en":{"metaTitle":"Edit route"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-routes-gymRouteId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/routes/:gymRouteId?/picture",
    component: _40273475,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Définir une photo"},"en":{"metaTitle":"Define the picture"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-routes-gymRouteId-picture"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/routes/:gymRouteId?/thumbnail",
    component: _3f6805a3,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Définir une miniature"},"en":{"metaTitle":"Define thumbnail"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-routes-gymRouteId-thumbnail"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/sectors/:gymSectorId?/edit",
    component: _365942f2,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-sectors-gymSectorId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/sectors/:gymSectorId?/routes/new",
    component: _24734998,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une ligne"},"en":{"metaTitle":"Add a new route"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-sectors-gymSectorId-routes-new"
  }, {
    path: "/organizations/:organizationId?/:organizationName?/edit",
    component: _43558391,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "organizations-organizationId-organizationName-edit"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
